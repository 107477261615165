import React, { useState } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import globalStrings from '../../config/index';

interface HamburgerMenuProps {
  onClick: () => void;
}

const HamburgerMenu: React.FC<HamburgerMenuProps> = ({ onClick }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { home, services, portfolio, about, careers, contact } =
    globalStrings.navigation;

  const menuItemClassCss = classNames(
    'border-transparent border-b-2 uppercase text-2xl font-normal relative'
  );
  const activeMenuItemClassCss = classNames(
    '!border-ensemble-orange font-semibold'
  );

  const closeMenu = () => {
    setIsOpen(false);
    onClick();
  };

  const openMenu = () => {
    setIsOpen(true);
    onClick();
  };

  return (
    <div className='lg:hidden'>
      <a onClick={openMenu}>
        <img
          src={require(`../../images/navbar/hamburger-menu.svg`).default}
          alt='hamburger-menu'
          className='flex pl-7'
        />
      </a>
      <div
        className={`container bg-white z-[500] fixed inset-0 max-w-full overflow-y-scroll ${
          isOpen ? 'block' : 'hidden'
        }`}
      >
        <a
          onClick={closeMenu}
          className='flex justify-end mt-11 sm:mr-8 sm:mt-6'
        >
          <img
            className='w-4 h-4 mr-4'
            src={require(`../../images/navbar/close-icon.svg`).default}
            alt='Close menu'
          />
        </a>
        <div className='flex flex-col gap-y-10 items-center justify-center lg:block text-[#333333] pt-[93px] pb-[93px]'>
          <Link
            className={menuItemClassCss}
            to='/'
            activeClassName={`${activeMenuItemClassCss} homeMobileMenuClassCss`}
            onClick={closeMenu}
          >
            {home}
          </Link>
          <Link
            className={menuItemClassCss}
            to='/services'
            activeClassName={activeMenuItemClassCss}
            partiallyActive
            onClick={closeMenu}
          >
            {services}
          </Link>
          <Link
            className={menuItemClassCss}
            to='/portfolio'
            activeClassName={activeMenuItemClassCss}
            partiallyActive={false}
            onClick={closeMenu}
          >
            {portfolio}
          </Link>
          <Link
            className={menuItemClassCss}
            to='/about'
            activeClassName={activeMenuItemClassCss}
            partiallyActive
            onClick={closeMenu}
          >
            {about}
          </Link>
          <Link
            className={menuItemClassCss}
            to='/careers'
            activeClassName={activeMenuItemClassCss}
            partiallyActive
            onClick={closeMenu}
          >
            {careers}
          </Link>
          <Link
            className={menuItemClassCss}
            to='/contact'
            activeClassName={activeMenuItemClassCss}
            partiallyActive
            onClick={closeMenu}
          >
            {contact}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HamburgerMenu;
